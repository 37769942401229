/*
waitMe - 1.15 [15.02.16]
Author: vadimsva
Github: https://github.com/vadimsva/waitMe
*/
.waitMe_container {
  position: relative;
  transform: translateZ(0);
  backface-visibility: hidden
}

body.waitMe_container {
  position: fixed;
  width: 100%;
  height: 100%
}

.waitMe_container .waitMe {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9989;
  text-align: center;
  overflow: hidden
}

.waitMe_container .waitMe * {
  font-family: sans-serif;
  font-size: 14px;
  font-weight: initial;
  font-style: initial;
  line-height: initial;
  color: initial;
  text-decoration: initial;
  text-transform: initial;
  padding: initial;
  margin: initial
}

.waitMe_container .waitMe .waitMe_content {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  backface-visibility: hidden
}

.waitMe_container .waitMe .waitMe_progress {
  position: relative;
  font-size: 0
}

.waitMe_container .waitMe .waitMe_progress > div {
  animation-fill-mode: both;
  display: inline-block;
  transform: translateZ(0);
  backface-visibility: hidden
}

.waitMe_container .waitMe .waitMe_text {
  position: relative;
  margin: 20px 0 0
}

/* before load animation */
body.waitMe_body {
  overflow: hidden;
  height: 100%
}

body.waitMe_body.hideMe {
  transition: opacity .2s ease-in-out;
  opacity: 0
}

body.waitMe_body .waitMe_container:not([data-waitme_id]) {
  position: fixed;
  z-index: 9989;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff
}

body.waitMe_body .waitMe_container:not([data-waitme_id]) > div {
  animation-fill-mode: both;
  position: absolute
}

body.waitMe_body .waitMe_container.progress > div {
  width: 0;
  height: 3px;
  top: 0;
  left: 0;
  background: #000;
  box-shadow: -5px 0 5px 2px rgba(0, 0, 0, .2);
  animation: progress_body 7s infinite ease-out
}

body.waitMe_body .waitMe_container.working > div {
  width: 10%;
  height: 3px;
  top: 0;
  left: -10%;
  background: #000;
  box-shadow: -5px 0 5px 2px rgba(0, 0, 0, .2);
  animation: working_body 2s infinite linear
}

body.waitMe_body .waitMe_container.progress > div:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 60%;
  right: 0;
  width: 60px;
  border-radius: 50%;
  opacity: .5;
  transform: rotate(3deg);
  box-shadow: #000 1px 0 6px 1px
}

body.waitMe_body .waitMe_container.img > div {
  width: 100%;
  height: 100%;
  text-align: center;
  background-position: center !important;
  background-repeat: no-repeat !important
}

body.waitMe_body .waitMe_container.text > div {
  width: 100%;
  top: 45%;
  text-align: center
}

@keyframes progress_body {
  0% {
    width: 0
  }
  100% {
    width: 100%
  }
}

@keyframes working_body {
  0% {
    left: -10%
  }
  100% {
    left: 100%
  }
}

/* bounce */
.waitMe_container .waitMe_progress.bounce > div {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  animation: bounce 1.4s infinite ease-in-out
}

.waitMe_container .waitMe_progress.bounce .waitMe_progress_elem1 {
  animation-delay: -.32s
}

.waitMe_container .waitMe_progress.bounce .waitMe_progress_elem2 {
  animation-delay: -.16s
}

@keyframes bounce {
  0%, 80%, 100% {
    transform: scale(0)
  }
  40% {
    transform: scale(1)
  }
}

/* rotateplane */
.waitMe_container .waitMe_progress.rotateplane > div {
  width: 30px;
  height: 30px;
  animation: rotateplane 1.2s infinite ease-in-out;
  backface-visibility: visible
}

@keyframes rotateplane {
  0% {
    transform: perspective(120px)
  }
  50% {
    transform: perspective(120px) rotateY(180deg)
  }
  100% {
    transform: perspective(120px) rotateY(180deg) rotateX(180deg)
  }
}

/* stretch */
.waitMe_container .waitMe_progress.stretch > div {
  width: 1px;
  height: 60px;
  margin: 4px;
  animation: stretch 1.2s infinite ease-in-out
}

.waitMe_container .waitMe_progress.stretch .waitMe_progress_elem2 {
  animation-delay: -1.1s
}

.waitMe_container .waitMe_progress.stretch .waitMe_progress_elem3 {
  animation-delay: -1s
}

.waitMe_container .waitMe_progress.stretch .waitMe_progress_elem4 {
  animation-delay: -.9s
}

.waitMe_container .waitMe_progress.stretch .waitMe_progress_elem5 {
  animation-delay: -.8s
}

@keyframes stretch {
  0%, 40%, 100% {
    transform: scaleY(.4)
  }
  20% {
    transform: scaleY(1)
  }
}

/* orbit */
.waitMe_container .waitMe_progress.orbit {
  width: 40px;
  height: 40px;
  margin: auto;
  animation: orbit_rotate 2s infinite linear
}

.waitMe_container .waitMe_progress.orbit > div {
  width: 50%;
  height: 50%;
  border-radius: 50%;
  top: 10%;
  left: 10%;
  position: absolute;
  animation: orbit 2s infinite ease-in-out
}

.waitMe_container .waitMe_progress.orbit .waitMe_progress_elem2 {
  top: auto;
  bottom: 10%;
  left: auto;
  right: 10%;
  animation-delay: -1s
}

@keyframes orbit_rotate {
  100% {
    transform: rotate(360deg) scale(1)
  }
}

@keyframes orbit {
  0%, 100% {
    transform: scale(0)
  }
  50% {
    transform: scale(1)
  }
}

/* roundBounce */
.waitMe_container .waitMe_progress.roundBounce {
  width: 60px;
  height: 60px;
  margin: auto
}

.waitMe_container .waitMe_progress.roundBounce > div {
  width: 24%;
  height: 24%;
  border-radius: 50%;
  position: absolute;
  animation: roundBounce 1.2s infinite ease-in-out
}

.waitMe_container .waitMe_progress.roundBounce .waitMe_progress_elem1 {
  top: 0;
  left: 0
}

.waitMe_container .waitMe_progress.roundBounce .waitMe_progress_elem2 {
  top: 0;
  right: 0
}

.waitMe_container .waitMe_progress.roundBounce .waitMe_progress_elem3 {
  bottom: 0;
  right: 0
}

.waitMe_container .waitMe_progress.roundBounce .waitMe_progress_elem4 {
  bottom: 0;
  left: 0
}

.waitMe_container .waitMe_progress.roundBounce .waitMe_progress_elem5 {
  top: -3%;
  left: 50%;
  margin-top: -12%;
  margin-left: -12%
}

.waitMe_container .waitMe_progress.roundBounce .waitMe_progress_elem6 {
  top: 50%;
  right: -3%;
  margin-top: -12%;
  margin-right: -12%
}

.waitMe_container .waitMe_progress.roundBounce .waitMe_progress_elem7 {
  bottom: -3%;
  left: 50%;
  margin-bottom: -12%;
  margin-left: -12%
}

.waitMe_container .waitMe_progress.roundBounce .waitMe_progress_elem8 {
  top: 50%;
  left: -3%;
  margin-top: -12%;
  margin-left: -12%
}

.waitMe_container .waitMe_progress.roundBounce .waitMe_progress_elem9 {
  top: 0;
  right: 0
}

.waitMe_container .waitMe_progress.roundBounce .waitMe_progress_elem10 {
  bottom: 0;
  right: 0
}

.waitMe_container .waitMe_progress.roundBounce .waitMe_progress_elem11 {
  bottom: 0;
  left: 0
}

.waitMe_container .waitMe_progress.roundBounce .waitMe_progress_elem12 {
  top: 0;
  left: 0
}

.waitMe_container .waitMe_progress.roundBounce .waitMe_progress_elem5 {
  animation-delay: -1.1s
}

.waitMe_container .waitMe_progress.roundBounce .waitMe_progress_elem9 {
  animation-delay: -1s
}

.waitMe_container .waitMe_progress.roundBounce .waitMe_progress_elem2 {
  animation-delay: -.9s
}

.waitMe_container .waitMe_progress.roundBounce .waitMe_progress_elem6 {
  animation-delay: -.8s
}

.waitMe_container .waitMe_progress.roundBounce .waitMe_progress_elem10 {
  animation-delay: -.7s
}

.waitMe_container .waitMe_progress.roundBounce .waitMe_progress_elem3 {
  animation-delay: -.6s
}

.waitMe_container .waitMe_progress.roundBounce .waitMe_progress_elem7 {
  animation-delay: -.5s
}

.waitMe_container .waitMe_progress.roundBounce .waitMe_progress_elem11 {
  animation-delay: -.4s
}

.waitMe_container .waitMe_progress.roundBounce .waitMe_progress_elem4 {
  animation-delay: -.3s
}

.waitMe_container .waitMe_progress.roundBounce .waitMe_progress_elem8 {
  animation-delay: -.2s
}

.waitMe_container .waitMe_progress.roundBounce .waitMe_progress_elem12 {
  animation-delay: -.1s
}

@keyframes roundBounce {
  0%, 80%, 100% {
    transform: scale(0)
  }
  40% {
    transform: scale(1)
  }
}

/* win8 */
.waitMe_container .waitMe_progress.win8 {
  width: 40px;
  height: 40px;
  margin: auto
}

.waitMe_container .waitMe_progress.win8 > div {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  transform: rotate(225deg);
  animation: win8 5.5s infinite
}

.waitMe_container .waitMe_progress.win8 > div > div {
  width: 15%;
  height: 15%;
  border-radius: 50%;
  position: absolute
}

.waitMe_container .waitMe_progress.win8 .waitMe_progress_elem2 {
  animation-delay: .24s
}

.waitMe_container .waitMe_progress.win8 .waitMe_progress_elem3 {
  animation-delay: .48s
}

.waitMe_container .waitMe_progress.win8 .waitMe_progress_elem4 {
  animation-delay: .72s
}

.waitMe_container .waitMe_progress.win8 .waitMe_progress_elem5 {
  animation-delay: .96s
}

@keyframes win8 {
  0% {
    transform: rotate(225deg);
    animation-timing-function: ease-out
  }
  7% {
    opacity: 1;
    transform: rotate(345deg);
    animation-timing-function: linear
  }
  30% {
    transform: rotate(455deg);
    animation-timing-function: ease-in-out
  }
  39% {
    transform: rotate(690deg);
    animation-timing-function: linear
  }
  70% {
    opacity: 1;
    transform: rotate(815deg);
    animation-timing-function: ease-out
  }
  75% {
    transform: rotate(945deg);
    animation-timing-function: ease-out
  }
  76% {
    opacity: 0;
    transform: rotate(945deg)
  }
  100% {
    opacity: 0;
    transform: rotate(945deg)
  }
}

/* win8_linear */
.waitMe_container .waitMe_progress.win8_linear {
  margin: auto;
  width: 150px;
  height: 6px
}

.waitMe_container .waitMe_progress.win8_linear > div {
  width: 100%;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  animation: win8_linear 3s infinite
}

.waitMe_container .waitMe_progress.win8_linear > div > div {
  width: 4%;
  height: 100%;
  border-radius: 50%
}

.waitMe_container .waitMe_progress.win8_linear .waitMe_progress_elem2 {
  animation-delay: .3s
}

.waitMe_container .waitMe_progress.win8_linear .waitMe_progress_elem3 {
  animation-delay: .6s
}

.waitMe_container .waitMe_progress.win8_linear .waitMe_progress_elem4 {
  animation-delay: .9s
}

.waitMe_container .waitMe_progress.win8_linear .waitMe_progress_elem5 {
  animation-delay: 1.2s
}

@keyframes win8_linear {
  0% {
    transform: translateX(0);
    animation-timing-function: ease-out
  }
  10% {
    opacity: 1;
    transform: translateX(33.333%);
    animation-timing-function: linear
  }
  50% {
    opacity: 1;
    transform: translateX(53.333%);
    animation-timing-function: ease-in-out
  }
  60% {
    opacity: 0;
    transform: translateX(86.666%)
  }
}

/* ios */
.waitMe_container .waitMe_progress.ios {
  margin: auto;
  width: 40px;
  height: 40px
}

.waitMe_container .waitMe_progress.ios > div {
  width: 10%;
  height: 26%;
  position: absolute;
  left: 44.5%;
  top: 37%;
  opacity: 0;
  border-radius: 50px;
  box-shadow: 0 0 3px rgba(0, 0, 0, .2);
  animation: ios 1s infinite linear
}

.waitMe_container .waitMe_progress.ios .waitMe_progress_elem1 {
  transform: rotate(0deg) translate(0, -142%);
  animation-delay: 0s
}

.waitMe_container .waitMe_progress.ios .waitMe_progress_elem2 {
  transform: rotate(30deg) translate(0, -142%);
  animation-delay: -.9167s
}

.waitMe_container .waitMe_progress.ios .waitMe_progress_elem3 {
  transform: rotate(60deg) translate(0, -142%);
  animation-delay: -.833s
}

.waitMe_container .waitMe_progress.ios .waitMe_progress_elem4 {
  transform: rotate(90deg) translate(0, -142%);
  animation-delay: -.75s
}

.waitMe_container .waitMe_progress.ios .waitMe_progress_elem5 {
  transform: rotate(120deg) translate(0, -142%);
  animation-delay: -.667s
}

.waitMe_container .waitMe_progress.ios .waitMe_progress_elem6 {
  transform: rotate(150deg) translate(0, -142%);
  animation-delay: -.5833s
}

.waitMe_container .waitMe_progress.ios .waitMe_progress_elem7 {
  transform: rotate(180deg) translate(0, -142%);
  animation-delay: -.5s
}

.waitMe_container .waitMe_progress.ios .waitMe_progress_elem8 {
  transform: rotate(210deg) translate(0, -142%);
  animation-delay: -.41667s
}

.waitMe_container .waitMe_progress.ios .waitMe_progress_elem9 {
  transform: rotate(240deg) translate(0, -142%);
  animation-delay: -.333s
}

.waitMe_container .waitMe_progress.ios .waitMe_progress_elem10 {
  transform: rotate(270deg) translate(0, -142%);
  animation-delay: -.25s
}

.waitMe_container .waitMe_progress.ios .waitMe_progress_elem11 {
  transform: rotate(300deg) translate(0, -142%);
  animation-delay: -.1667s
}

.waitMe_container .waitMe_progress.ios .waitMe_progress_elem12 {
  transform: rotate(330deg) translate(0, -142%);
  animation-delay: -.0833s
}

@keyframes ios {
  0% {
    opacity: 1
  }
  100% {
    opacity: .25
  }
}

/* facebook */
.waitMe_container .waitMe_progress.facebook {
  margin: auto
}

.waitMe_container .waitMe_progress.facebook > div {
  width: 6px;
  height: 25px;
  margin-left: 3px;
  border-radius: 20px;
  transform: scaleY(.7);
  opacity: .1;
  animation: facebook 1.3s infinite ease-in-out
}

.waitMe_container .waitMe_progress.facebook > .waitMe_progress_elem1 {
  animation-delay: .2s;
  transform: scaleY(.7)
}

.waitMe_container .waitMe_progress.facebook > .waitMe_progress_elem2 {
  animation-delay: .4s;
  transform: scaleY(.85)
}

.waitMe_container .waitMe_progress.facebook > .waitMe_progress_elem3 {
  animation-delay: .6s;
  transform: scaleY(1)
}

@keyframes facebook {
  0% {
    transform: scaleY(.7);
    opacity: .1
  }
  50% {
    transform: scaleY(1);
    opacity: 1
  }
  100% {
    transform: scaleY(.7);
    opacity: .1
  }
}

/* rotation */
.waitMe_container .waitMe_progress.rotation > div {
  width: 60px;
  height: 60px;
  margin: auto;
  border-radius: 100%;
  border-width: 6px;
  border-style: solid;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  animation: rotation 1s infinite linear
}

@keyframes rotation {
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
}

/* timer */
.waitMe_container .waitMe_progress.timer {
  width: 40px;
  height: 40px;
  margin: auto;
  border-width: 2px;
  border-style: solid;
  border-radius: 50%;
  box-sizing: border-box;
  position: relative;
  text-indent: -9999px
}

.waitMe_container .waitMe_progress.timer > .waitMe_progress_elem1,
.waitMe_container .waitMe_progress.timer > .waitMe_progress_elem2 {
  border-radius: 3px;
  position: absolute;
  width: 2px;
  height: 48%;
  left: 50%;
  top: 50%;
  margin-left: -1px;
  margin-top: -1px;
  animation: timer 1.25s infinite linear;
  transform-origin: 1px 1px
}

.waitMe_container .waitMe_progress.timer > .waitMe_progress_elem2 {
  height: 40%;
  animation: timer 15s infinite linear
}

@keyframes timer {
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
}

/* pulse */
.waitMe_container .waitMe_progress.pulse {
  width: 30px;
  height: 30px;
  margin: auto;
  position: relative
}

.waitMe_container .waitMe_progress.pulse > div {
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-width: 3px;
  border-style: solid;
  border-radius: 50%;
  position: absolute;
  opacity: 0;
  animation: pulsate 1s infinite ease-out
}

@keyframes pulsate {
  0% {
    transform: scale(.1);
    opacity: 0
  }
  50% {
    opacity: 1
  }
  100% {
    transform: scale(1.2);
    opacity: 0
  }
}

/* progressBar */
.waitMe_container .waitMe_progress.progressBar {
  width: 200px;
  height: 20px;
  margin: auto;
  background: rgba(0, 0, 0, .1);
  padding: 5px;
  border-radius: 20px;
  line-height: 0;
  max-width: 100%
}

.waitMe_container .waitMe_progress.progressBar > div {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 20px;
  background-size: 50px 50px;
  box-shadow: inset 0 2px 9px rgba(255, 255, 255, .3), inset 0 -2px 6px rgba(0, 0, 0, .4);
  background-image: linear-gradient(-45deg, rgba(240, 240, 240, .4) 26%, transparent 25%, transparent 51%, rgba(240, 240, 240, .4) 50%, rgba(240, 240, 240, .4) 76%, transparent 75%, transparent);
  animation: progressBar 2s linear infinite
}

@keyframes progressBar {
  0% {
    background-position: 0 0
  }
  100% {
    background-position: 50px 50px
  }
}

/* bouncePulse */
.waitMe_container .waitMe_progress.bouncePulse > div {
  width: 20px;
  height: 20px;
  margin-right: 1%;
  display: inline-block;
  border-radius: 50%;
  transform: scale(.5);
  animation: bouncePulse 1.4s infinite ease-in-out
}

.waitMe_container .waitMe_progress.bouncePulse > .waitMe_progress_elem1,
.waitMe_container .waitMe_progress.bouncePulse > .waitMe_progress_elem3 {
  animation-delay: .3s
}

.waitMe_container .waitMe_progress.bouncePulse > .waitMe_progress_elem2 {
  animation-delay: .1s
}

@keyframes bouncePulse {
  0%, 90%, 100% {
    transform: scale(.5)
  }
  45% {
    transform: scale(1)
  }
}